html {
    scrollbar-gutter: stable;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    justify-content: center;
}

.normal {
    font-weight: 400;
}

.bold {
    font-weight: 600;
}

code {
    font-family: inherit;
    font-size: inherit;
}

img[alt="photo"] {
    border: 1px solid lightblue;
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
    margin-right: 21px;
    margin-bottom: 21px;
}
